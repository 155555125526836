@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

html {
	font-family: 'Montserrat', sans-serif;
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;

}


.App {
	width: 1100px;
	margin: 0 auto;
}

h1 {
	font-size: 3em;
	margin-top: 45px;
}

#mainSvgDiv {
	margin:  auto;
	width: 1100px;
	/* make inner div to be center aligned */
	display: flex;
	justify-content: center;
	margin-bottom: 40px;
	padding-bottom: 10px;

}

#demoDiv {

	border: 1px solid #aaa;
	border-radius: 10px;
}

#mainCanvas {
	border: 1px solid #000;
	margin-top: 20px;
	margin-bottom: 20px;
}

.buttonDesc {
	font-size:0.95em;
	margin-top: 5px;
}

#clamsGMMCanvas {
		margin-top: 20px;
			margin-left: 20px;
}

#sepAmbSvg {
	margin-top: 20px;
	margin-left: 20px;
}

#mainCanvasDiv {
	width: 500px;
}

h1 {
	text-align: center;
	font-size: 3em;
	margin-top:55px;
	margin-bottom: 30px;
}

h2 {
	font-weight: 300;
	/* center align */
	text-align: center;
	font-family: 'Montserrat', sans-serif;
}

.suppDiv {
	margin: 0 auto;
	width: 820px;
}

p {
	font-size: 1.2em;
	font-weight: 300;
}

#mainSvgButtonDiv {
	margin: 0 auto;
	width: 500px;
	/* make inner buttons to be right aligned */
	display: flex;
	justify-content: space-between;

}

.svgButton {
	height: 50px;
		font-size: 1rem;
		font-family: 'Montserrat', sans-serif;
		/* margin-top: 10px; */
		margin-bottom: 10px;
		/* margin-right: 10px; */
		cursor: pointer;
		margin-left: 10px;
}

.svgSelect {
	width: 180px;
	height: 50px;
		font-size: 1rem;
		font-family: 'Montserrat', sans-serif;
		/* margin-top: 10px; */
		margin-bottom: 1.5px;
		/* margin-right: 10px; */
		cursor: pointer;

}

label {
	cursor: pointer;
	/* Style as you please, it will become the visible UI component. */
}

#svgInput {
	opacity: 0;
	position: absolute;
	z-index: -1;
}

#uploadButtonDiv {
	/* make it like button */
	width: 190px;
	height: 50px;
	background-color: #eee;
	border: 1px solid #444;
	border-radius: 2px;
	cursor: pointer;
	/* center align */
	display: flex;
	justify-content: center;
	align-items: center;
}


#initializeButton {
	/* background-color:  #eee; */
	/* color: white; */
	font-weight: 800;
}

.mat {
	margin-top: 20px;
	margin-left: 20px;
}

#ambDescription {
	/* left align contents */
	/* text-align: left; */
	display: flex;
	justify-content: end;
}

#ambDescriptionP {
	/* left algin */
	text-align: left;
	display: block;
	/* margin: auto; */

}

#clamsResultDiv {
	position: relative;
}

#loadingAlarm {
	/*  locate in the center of the parent div, which is relative */
	position: absolute;
	top: 40%;
	left: 22%;

	font-size: 2em;

}

h3 {
	font-size: 1.5em;
	margin-bottom: 5px;
}

#titleDiv {
	/* make inner div to be center aligned */
	display: flex;
	justify-content: center;
}